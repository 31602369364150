.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadAnimation {
  margin: 5em;
  animation: .8s infinite beatLogo;
}

@keyframes beatLogo {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

body {
  background-image: url('./assets/img/background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.btn-primary {
  background-color: #3ca3dc;
}
textarea:focus, input:focus{
  outline: none;
  outline-style: none;
}
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
input {
  border: 1px solid #b1b1b1;
}
input:focus {
  border: 3px solid #3ca3dc;
}
.t333{
  color: #333333;
}
.t75 {
  color: #757575;
}
.t68 {
  color: #686868;
}
.b68 {
  border: 3px solid #686868!important;
}
.azul {
  color: #3ca3dc !important;
}
.w300 {
  font-weight: 300;
}
.smclose, .smclose:hover {
  float: right;
  width: 25px;
  height: 25px;
  padding: 0px;
  margin-top: 11px;
  background: none;
  border: none;
}

.form-control {
  height: calc(1.5em + 2rem + 2px);
  border: 1px solid #b1b1b1;  
}
.list-group-item {
  border-color: #b1b1b1;
}
.btn-primary {
  padding: 1rem .75rem;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: #008bb5!important;
  border: 1px solid #008bb5!important;
}

:root {
  --input-padding-x: 1rem;
  --input-padding-y: 1.1rem;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #b1b1b1;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown), .form-label-group input:focus {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label, .form-label-group input:focus ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

/* CFlex.justify_content_end */
@media (max-width: 1023px) {
  .right-painel {
    width: 100%;
  }
  .left-painel {
    padding: 50px;
  }   
}

@media (min-width: 1024px) {
  .right-painel {
    width: 40%;
  }
  .right-painel > div {
    max-width: 424px;
  }
  .left-painel {
    padding: 100px;
  }  
}
/* FIM CFlex.justify_content_end */