body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: darkgrey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-primary {
  background-color: #00adee;
  border-color: #00adee;
}

.s:focus {
  outline: none!important;
}
.e:focus {
  box-shadow: 0 0 6px orange;
}
.c:focus {
  box-shadow: 0 0 6px green;
}